<template>
  <div class="mainBox" >
    <div class="head">
      <div @click="goGomain" style="height: 45px; line-height: 45px">
        <el-icon><ArrowLeftBold /></el-icon>
      </div>
      <div class="head-left" @click="goOnlineExpert">
        <img src="../../assets/mainLogo.png" alt="" />
      </div>
      <p class="head-right" @click="conLogOut">
        <svg-icon icon="tuichu"></svg-icon>

        <span style="margin-left: 10px; color: #000000">Sign Out</span>
      </p>
    </div>

    <el-collapse>
      <el-collapse-item name="3"> </el-collapse-item>
      <el-collapse-item title="Search" name="2">
        <div class="demo-date-picker">
          <div style="width: 100%" class="timeBox">
            <div>Start time:</div>
            <el-date-picker
              v-model="DateTime1"
              type="datetime"
              placeholder="Select start time"
            />
          </div>
          <div class="timeBox">
            <div>End time:</div>
            <el-date-picker
              v-model="DateTime2"
              type="datetime"
              placeholder="Select end time"
            />
          </div>
          <div class="search">
            <el-button type="primary" @click="chooseTime">Search</el-button>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>

    <div style="display: block; margin: 10px 5px; color: rgb(128 66 175)">
      Comparing with {{ dateArr[0] ? dateArr[0] : defaultTimeSta }} -
      {{ dateArr[1] ? dateArr[1] : defaultTimeEnd }}
    </div>

    <div style="padding: 5px">
      <div class="card" v-for="(item, index) in headerList" :key="index">
        <p class="p">{{ item.value }}</p>
        <p>{{ item.name }}</p>
      </div>

      <div id="box">
        <div class="box-header">
          <div>Earnings overview</div>
          <div>
            <!-- <el-button type="primary" plain>Primary</el-button>
                <el-button type="success" plain>Success</el-button> -->
          </div>
        </div>
        <div class="box-date">
          Comparing with {{ dateArr[0] ? dateArr[0] : defaultTimeSta }} -
          {{ dateArr[1] ? dateArr[1] : defaultTimeEnd }}
        </div>
        <div id="main">
          <!-- 图表 -->
        </div>
      </div>

      <div id="box1">
        <div class="box-header">
          <div>Working time</div>
          <div>
            <!-- <el-button type="primary" plain>Primary</el-button>
                <el-button type="success" plain>Success</el-button> -->
          </div>
        </div>
        <div class="box-date">
          Comparing with {{ dateArr[0] ? dateArr[0] : defaultTimeSta }} -
          {{ dateArr[1] ? dateArr[1] : defaultTimeEnd }}
        </div>
        <div id="main2">
          <!-- 图表 -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { ArrowLeftBold } from "@element-plus/icons-vue";
import * as echarts from "echarts";
import { size } from "lodash";
import { nextTick, onMounted } from "vue";
import request from "@/utils/request.js";
import { getEchartsData } from "@/api/center.js";
import { ref } from "vue";
import { id } from "element-plus/es/locale";
import { useRouter, useRoute } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import { removeToken, removeType, removeConlant } from "@/utils/auth";
import {
  getConmation,
  reviseConsulantInformation,
  updateConPass,
  logOut,
} from "@/api/center.js";
const store = useStore();
const router = useRouter();
const route = useRoute();
const headerList = ref([]);
const dataList = ref([]);
const dataList2 = ref([]);
const DateTime1 = ref("");
const DateTime2 = ref("");
const dateArr = ref([]);
const consultantId = ref(null);
const defaultTimeSta = ref(new Date().setMonth(new Date().getMonth() - 1));
const defaultTimeEnd = ref(new Date());

const defaultTime2 = [new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 2, 1, 23, 59, 59)];

const shortcuts = [
  {
    text: "Last week",
    value: () => {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      return [start, end];
    },
  },
  {
    text: "Last month",
    value: () => {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      return [start, end];
    },
  },
  {
    text: "Last 3 months",
    value: () => {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
      return [start, end];
    },
  },
];

const conLogOut = () => {
  ElMessageBox.confirm("Are you sure you want out?", {
    confirmButtonText: "OK",
    cancelButtonText: "Cancel",
    type: "warning",
  })
    .then(() => {
      ElMessage({
        type: "success",
        message: "Exit successfully",
      });
      logOut().then(() => {
        removeToken();
        removeType();
        removeConlant();
        localStorage.clear();
        store.state.userInfo = null;
        window.localStorage.removeItem("userInfo");
        window.localStorage.removeItem("token");
        router.push("/onlineExpert");
      });
    })
    .catch(() => {
      ElMessage({
        type: "info",
        message: "unexit",
      });
    });

  return;
};
const goOnlineExpert = () => {
  router.push("/onlineExpert");
};

onMounted(() => {
  window.addEventListener("resize", function () {
    console.log("window.innerWidth", window.innerWidth);
  });

  let userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
  consultantId.value = userInfo.id;
  //默认传参时间
  let query = {
    startTimeD: ProcessingTime(new Date().setMonth(new Date().getMonth() - 1)),
    endTimeD: ProcessingTime(new Date()),
    consultantId: consultantId.value,
  };
  //调Echarts数据
  getList(query);
  //默认时间
  defaultTimeSta.value = zhuanHua(defaultTimeSta.value);
  defaultTimeEnd.value = zhuanHua(defaultTimeEnd.value);
});

const goGomain = () => {
  router.push("/profileInfo/home");
};

//选择时间触发事件
const chooseTime = async () => {
  if (DateTime1.value && DateTime2.value) {
    if (DateTime1.value > DateTime2.value) {
      ElMessage("The start time must not be later than the end time.");
      return;
    }

    let e = [DateTime1.value, DateTime2.value];
    let c = e;
    c = c.map((item) => zhuanHua(item));
    dateArr.value = c;
    e = e.map((item) => ProcessingTime(item));
    //转化时间格式
    const query = {
      startTimeD: e[0],
      endTimeD: e[1],
      consultantId: consultantId.value,
    };
    getList(query);
  } else {
    ElMessage("Please select the time first.");
  }
};

//时间转化
const zhuanHua = (dateString) => {
  // const dateString = "Sat May 06 2023 00:00:00 GMT+0800";
  const date = new Date(dateString);

  const monthIndex = date.getMonth();
  const day = date.getDate();

  // 定义英文月份的数组
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // 获取指定索引处的英文月份
  const month = months[monthIndex];
  return `${month} ${day}`;
};

//时间转换函数
const ProcessingTime = (time) => {
  const date = new Date(time);
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // 月份从0开始，所以需要加1
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const paddedHours = hours < 10 && hours >= 0 ? `0${hours}` : hours;
  const paddedMinutes = minutes < 10 && minutes >= 0 ? `0${minutes}` : minutes;
  const paddedSeconds = seconds < 10 && seconds >= 0 ? `0${seconds}` : seconds;
  return `${year}-${month}-${day} ${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
};

//获取数据
const getList = async (params) => {
  let res = await getEchartsData(params);
  if (res.code == "200") {
    let a = res.data;
    let allPrice = a.sumPerMinutes + a.sumChatGift + a.sumLiveGift + a.sumGift;
    dataList.value = [
      {
        value: a.sumPerMinutes,
        name: "Private Session",
        text: ((a.sumPerMinutes / allPrice) * 100).toFixed(1) + "%",
      },
      {
        value: a.sumChatGift,
        name: "Private Session Gift",
        text: ((a.sumChatGift / allPrice) * 100).toFixed(1) + "%",
      },
      {
        value: a.sumLiveGift,
        name: "Live",
        text: ((a.sumLiveGift / allPrice) * 100).toFixed(1) + "%",
      },
      {
        value: a.sumGift,
        name: "Gift",
        text: ((a.sumGift / allPrice) * 100).toFixed(1) + "%",
      },
    ];
    dataList.value.map((item) => {
      if (item.text == "NaN%" || item.text == "Infinity%" || item.text == "-Infinity%") {
        return (item.text = "0%");
      }
      return item;
    });

    let allPrice2 = a.sumTimeLiveSeconds + a.sumTimeChatSeconds;
    dataList2.value = [
      {
        value: a.sumTimeLiveSeconds,
        values: a.sumTimeLive,
        name: "Free Live",
        text: ((a.sumTimeLiveSeconds / allPrice2) * 100).toFixed(1) + "%",
      },
      {
        value: a.sumTimeChatSeconds,
        values: a.sumTimeChat,
        name: "Private Session",
        text: ((a.sumTimeChatSeconds / allPrice2) * 100).toFixed(1) + "%",
      },
    ];
    headerList.value = [
      { name: "Total Earnings", value: "$" + a.TotalRevenue },
      { name: "Avg/Hour", value: "$" + a.svnRevenue },
      { name: "Private Session", value: a.sumTimeChat },
      { name: "Live", value: a.sumTimeLive },
      { name: "New Members", value: a.sumFollower },
    ];

    dataList2.value.map((item) => {
      if (item.text == "NaN%" || item.text == "Infinity%" || item.text == "-Infinity%") {
        return (item.text = "0%");
      }
      return item;
    });

    initEcharts(dataList.value);
    initEcharts2(dataList2.value);
  }
};

//初始化图表
const initEcharts = (data) => {
  console.log("进入图表一", data);
  let chartDom = document.getElementById("main");
  let myChart = echarts.init(chartDom);
  let option;
  option = {
    color: ["#9252f3", "#fbcb3e", "#f05486", "red"],
    grid: {
      containLabel: true,
      left: "10%", // 设置图表容器左边距
      top: "10%", // 设置图表容器上边距
      right: "10%", // 设置图表容器右边距
      bottom: "10%", // 设置图表容器下边距
    },
    tooltip: {
      trigger: "item",
    },
    legend: {
      top: "18%",
      left: "60%",
      orient: "vertical", // 将图例垂直排列
      itemGap: 30,
      right: "50%",
      formatter: (name) => {
        if (data.length > 0) {
          const item = data.filter((item) => item.name == name)[0];
          return (
            "{title|" +
            item.name +
            "}{value|" +
            "$" +
            item.value +
            "}{text|" +
            item.text +
            "}"
          );
        }
      },
      textStyle: {
        rich: {
          title: {
            color: "#333333",
            fontSize: 20,
            padding: [3, 20, 3, 10],
            width: 100,
            fontSize: 14,
          },
          value: {
            fontSize: 22,
            lineHeight: 20,
            width: 40,
            aline: "right",
            padding: [3, 50],
            color: "#333333",
            fontSize: 18,
          },
          text: {
            fontSize: 22,
            lineHeight: 20,
            width: 40,
            aline: "right",
            padding: [3, 50],
            color: "#333333",
            fontSize: 18,
          },
        },
      },
    },
    grid: {
      left: "10%", // 距离左边界的距离
      right: "10%", // 距离右边界的距离    1
    },
    series: [
      {
        name: "Earnings overview",
        type: "pie",
        radius: ["50%", "70%"],
        avoidLabelOverlap: false,
        center: ["30%", "50%"],
        label: {
          show: false,
          position: "center",
          formatter: function (params) {
            return params.name + ": " + "$" + params.value;
          },
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 18,
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
        },
        data: data,
      },
    ],
  };

  option && myChart.setOption(option);
};

//初始化图表
const initEcharts2 = (data) => {
  console.log("进入图表二", data);
  let chartDom = document.getElementById("main2");
  let myChart = echarts.init(chartDom);
  let option;

  option = {
    color: ["#f05486", "#9252f3"],
    grid: {
      containLabel: true,
      left: "10%", // 设置图表容器左边距
      top: "10%", // 设置图表容器上边距
      right: "10%", // 设置图表容器右边距
      bottom: "10%", // 设置图表容器下边距
    },
    tooltip: {
      trigger: "item",
    },
    legend: {
      top: "36%",
      left: "60%",
      orient: "vertical", // 将图例垂直排列
      itemGap: 40,
      right: "50%",
      formatter: (name) => {
        if (data.length > 0) {
          const item = data.filter((item) => item.name == name)[0];
          return (
            "{title|" + item.name + "}{value|" + item.values + "}{text|" + item.text + "}"
          );
        }
      },
      textStyle: {
        rich: {
          title: {
            color: "#333333",
            fontSize: 20,
            padding: [3, 20, 3, 10],
            width: 100,
            fontSize: 14,
          },
          value: {
            fontSize: 22,
            lineHeight: 20,
            width: 40,
            aline: "right",
            padding: [3, 50],
            color: "#333333",
            fontSize: 18,
          },
          text: {
            fontSize: 22,
            lineHeight: 20,
            width: 40,
            aline: "right",
            padding: [3, 50],
            color: "#333333",
            fontSize: 18,
          },
        },
      },
    },

    grid: {
      left: "10%", // 距离左边界的距离
      right: "10%", // 距离右边界的距离
    },
    series: [
      {
        name: "Working time",
        type: "pie",
        radius: ["50%", "70%"],
        avoidLabelOverlap: false,
        center: ["30%", "50%"],
        label: {
          show: false,
          position: "center",
          formatter: function (params) {
            return params.name + ": " + params.data.values;
          },
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 18,
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
        },
        data: data,
      },
    ],
  };

  option && myChart.setOption(option);
};
</script>

<style lang="scss" scoped>
:deep(.el-collapse-item__header) {
  padding-left: 10px;
  background: rgb(188, 223, 234);
}

:deep(.el-collapse-item__wrap) {
  background: rgb(188, 223, 234);
}
.mainBox {
  background: linear-gradient(180deg, lightblue, #fff, #fff);
  overflow-y: auto;
  height:100vh;
  padding:5px;

}
.timeBox {
  display: flex;

  div {
    width: 25%;
    line-height: 35px;
    margin-bottom: 10px;
    padding-left: 10px;
  }
}

.search {
  text-align: center;
}

.card {
  width: 97%;
  padding: 5px;
  border-radius: 6px;
  background-color: #ffffff;
  margin: 10px 0;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.03), 0 10px 20px rgba(0, 0, 0, 0.05);

  .p {
    color: #9b6f80;
  }

  p {
    height: 25px;
    line-height: 25px;
  }
}

#box,
#box1 {
  width: 100%;
  height: 300px;
  background: #ffffff;
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
  .box-header {
    color: #800080;
    padding-left: 5px;
  }

  .box-date {
    color: #1a8f75;
    padding-left: 5px;
  }
}

#main {
  width: 100%;
  height: 100%;
}
#main2 {
  width: 100%;
  height: 100%;
}

.head {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: space-between;
  // padding: 0 10px;
  box-sizing: border-box;
  position: fixed; /* 固定在页面顶部 */
  top: 0; /* 顶部对齐 */
  left: 0; /* 左边对齐 */
  right: 0; /* 右边对齐 */
  z-index: 9;
  background: #ffffff;

  .head-right {
    background-color: #bddbe4;
    padding: 0 5px;
    box-shadow: inset 0 -2px 0 grey;
  }

  .head-left {
    width: 140.75px;
    height: 40px;
    margin-top: 3px;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .head-right {
    line-height: 45px;
  }
}
:deep(.el-collapse-item__content) {
  padding-bottom: 10px;
}
</style>
